.clickable {
  cursor: pointer;
}
.clickable-link {
  cursor: pointer;
  text-decoration: underline;
}

.flex-parent {
  display: flex;
  justify-content: center;
  align-items: center;  
}

.flex-grow {
  flex-grow: 1;
}

.flex-item {
  padding: .5em;
  text-align: center;
}


.MuiDialog-root {
  z-index: 20000 !important
}

.Toastify__toast-container {
  padding: 0;
  position: absolute;
  width: 100%;
}

.Toastify__toast {
  border-radius: 0;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  padding: 6px 8px 6px 16px;
}

.Toastify__toast-container--top-left {
  top: 0;
  left: 0;
}
/* 
.Toastify__toast--success {
  background: #4caf50;
}
    
.Toastify__toast--warning {
  background: #ff9800;
} */

.icon-spinner {
  animation: spin-animation infinite 2s linear;
  display: inline-block;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.icon-pulse {
  font-size: 50px;
  animation: heart-pulse 0.9s infinite ease-out;
}

@keyframes heart-pulse {
  0% {
    transform: scale(1); /* scaling to 0 */
  }
  50% {
    transform: scale(1.2); /* increasing the size */
  }
  70% {
    transform: scale(0.65); /* decreasing the size */
  }
  100% {
    transform: scale(1); /* seeting back to initial size */
  }
}

.button-list:not(:first-child) {
  margin-left: .5em;
}

.full-page-load {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.twothirds-page-load {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity .2s, visibility .2s, margin-top 0s .2s;
}

.half-page-load {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity .2s, visibility .2s, margin-top 0s .2s;
}

.quarter-page-load {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity .2s, visibility .2s, margin-top 0s .2s;
}


.lds-facebook {
  display: inline-block;
  position: relative;  
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: grey;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


.lmask {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.4;
}
.lmask.fixed {
  position: fixed;
}
.lmask:before {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid rgba(0, 183, 229, 0.9);
  opacity: 0.9;
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  box-shadow: 0 0 35px #2187e7;
  width: 50px;
  height: 50px;
  animation: spinPulse 1s infinite ease-in-out;
  -moz-animation: spinPulse 1s infinite ease-in-out;
  -webkit-animation: spinPulse 1s infinite linear;
  margin: -25px 0 0 -25px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.lmask:after {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid rgba(0, 183, 229, 0.9);
  opacity: 0.9;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  box-shadow: 0 0 15px #2187e7;
  width: 30px;
  height: 30px;
  animation: spinoffPulse 1s infinite linear;
  -moz-animation: spinoffPulse 1s infinite linear;
  -webkit-animation: spinoffPulse 1s infinite linear;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
  left: 50%;
}
@keyframes spinPulse {
  0% {
    transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7;
 }
  50% {
    transform: rotate(145deg);
    opacity: 1;
 }
  100% {
    transform: rotate(-320deg);
    opacity: 0;
 }
}
@keyframes spinoffPulse {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(360deg);
 }
}
@keyframes spinPulse {
  0% {
    transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7;
 }
  50% {
    transform: rotate(145deg);
    opacity: 1;
 }
  100% {
    transform: rotate(-320deg);
    opacity: 0;
 }
}
@keyframes spinoffPulse {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(360deg);
 }
}

.fadeOut {
  animation: fadeOut ease .5s;
  -webkit-animation: fadeOut ease .5s;
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;} 
} 

.fadeIn {
  display: inline-block;  
  text-transform: none;
  opacity: 1;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

